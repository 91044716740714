const SELECTOR = "[data-poll='true']"

class Poller {

  static start(selector = SELECTOR) {
    document.querySelectorAll(selector).forEach((element) => { new Poller(element).start() })
  }

  static stop(selector = SELECTOR) {
    document.querySelectorAll(SELECTOR).forEach((element) => {
      if (element.Poller) { element.Poller.stop(); }
    })
  }

  constructor(element) {
    if (element.Poller) return element.Poller;

    this.request = this.request.bind(this);
    this.element = element;
    this.token   = document.querySelector("meta[name=\"csrf-token\"]").content;

    element.Poller = this;

    return this;
  }

  interval() {
    return parseInt(this.element.dataset.interval || 1500);
  }

  url() {
    return this.element.dataset.url;
  }

  start() {
    return this.intervalId = setInterval(this.request, this.interval());
  }

  request() {
    let that = this;
    fetch(this.url(), {
      credentials: "same-origin",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        "X-CSRF-TOKEN": this.token,
      }
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.text();
      }).then((text) => {
        that.replace(text);
      }).catch((error) => {
        console.log("Error occured when getting element status, stopping polling...", error);
        that.stop();
      })
  }

  replace(text) {
    let div = document.createElement("div");
    div.classList = ["polling-placeholder"];
    div.innerHTML = text;

    let element = div.firstElementChild

    this.element.innerHTML = element.innerHTML;

    if (!element.dataset.poll) { return this.stop(); }
  }

  stop() {
    return clearInterval(this.intervalId);
  }
}

export default Poller
