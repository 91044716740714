import { Controller } from "stimulus"
import NProgress from 'nprogress/nprogress.js'

let showLastSelected = false;
let selectedScrollOffset = 0;

export default class extends Controller {
  static targets = [ "selected" ]

  connect() {
    if (showLastSelected) {
      this.selectedTarget.scrollLeft = this.selectedTarget.scrollLeftMax;
      showLastSelected = false
    } else {
      this.selectedTarget.scrollLeft = selectedScrollOffset
    }
  }

  showLastSelected() {
    showLastSelected = true
  }

  beforeAction() {
    Turbolinks.scrollTop = document.documentElement.scrollTop;
  }

  storeScroll() {
    selectedScrollOffset = this.selectedTarget.scrollLeft
  }
}
