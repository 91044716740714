import { Controller } from "stimulus"
import videojs from 'video.js';
import Impetus from '../vendor/impetus'

videojs.options.autoSetup = false

export default class extends Controller {
  connect() {
    this.content = this.element.querySelector(".content");

    if (this.data.has("video")) {
      this.loadPlayer()
    }

    this.impetus = new Impetus({
      source: this.element,
      initialValues: [0, 0],
      boundX: this.getBoundX(),
      boundY: this.getBoundY(),
      bounce: false,
      update: this.updateCallback.bind(this)
    })

    if (this.data.has("active")) {
      document.documentElement.classList.add("has-preview")
    } else {
      document.documentElement.classList.remove("has-preview")
    }
  }

  disconnect() {
    if (this.player)  { this.player.dispose()  }
    if (this.impetus) { this.impetus.destroy() }
  }

  loadPlayer() {
    this.player = videojs("#videojs", {
      controlBar: {
        pictureInPictureToggle: !!document.pictureInPictureEnabled
      }
    });
  }

  getBoundX() {
    return [
      this.nextSlide()     ? -this.clientWidth() / 2 : -50,
      this.previousSlide() ?  this.clientWidth() / 2 :  50
    ]
  }

  getBoundY() {
    return [0, 150]
  }

  clientWidth() {
    return document.querySelector("body").clientWidth;
  }

  clientHeight() {
    return this.content.parentElement.clientHeight;
  }

  updateCallback(x, y) {
    if (Math.abs(y) > Math.abs(x)) {
      this.__moveVertically(y);
    } else {
      this.__moveHorizontally(x);
    }
  }

  nextSlide() {
    return this.element.querySelector(".next.enabled a");
  }

  previousSlide() {
    return this.element.querySelector(".previous.enabled a");
  }

  resetView(animate) {
    if (animate) this.enableAnimation(200);

    this.impetus.setBoundX(this.getBoundX())
    this.__setTransform("translateX", 0);
    this.__setTransform("translateY", 0);
    this.impetus.setValues(0, 0);
  }

  loadNext() {
    if (!this.nextSlide()) return;

    this.nextSlide().click();
  }

  loadPrevious() {
    if (!this.previousSlide()) return;

    this.previousSlide().click();
  }

  close() {
    this.element.querySelector(".presentation-page a").click();
  }

  __moveVertically(y) {
    switch(y) {
      case(150):
        this.enableAnimation(200);     
        this.__setTransform("translateY", this.clientHeight());
         this.withTimer(this.close.bind(this), 100)

        break;
      default:
        this.__setTransform("translateY", y);
        this.withTimer(this.resetView.bind(this, true), 100);
    }
  }

  __moveHorizontally(x) {
    var that = this;
    switch(true) {
      case (x < -(this.clientWidth() / 2 - 10)):
        this.enableAnimation(200);
        this.__setTransform("translateX", -this.clientWidth());
        this.withTimer(this.loadNext.bind(this), 100);

        break;
      case (x > (this.clientWidth() / 2 - 10)):
        this.enableAnimation(200);
        this.__setTransform("translateX", this.clientWidth());
        this.withTimer(this.loadPrevious.bind(this), 100);

        break;
      default:
        this.__setTransform("translateX", x);
        this.withTimer(this.resetView.bind(this, true), 100);
    }
  }

  enableAnimation(duration) {
    this.content.classList.add("animate");

    setTimeout(() => {
      this.content.classList.remove("animate");
    }, duration)
  }

  withTimer(fun, timeout) {
    var that = this;
    clearTimeout(this.timer);

    this.timer = setTimeout(fun, timeout)
  }

  __setTransform(property, value) {
    var val = property + '(' + value + 'px' + ')';

    this.content.style.webkitTransform = val;
    this.content.style.MozTransform    = val;
    this.content.style.msTransform     = val;
    this.content.style.OTransform      = val;
    this.content.style.transform       = val;
  }
}

