import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.index = 0
    this.backgrounds = Array.from(this.element.querySelectorAll("div.background"))

    setTimeout(() => {
      this.element.querySelector(".shadow").style.opacity = 0.4
    }, 500)

    this.update()

    if (this.data.has("refreshInterval")) {
      this.startUpdating()
    }
  }

  update() {
    this.backgrounds[this.index].style.opacity = 0
    this.index = this.index + 1 >= this.backgrounds.length ? 0 : this.index + 1
    this.backgrounds[this.index].style.opacity = 1
  }

  startUpdating() {
    setInterval(() => {
      this.update()
    }, this.data.get("refreshInterval"))
  }
}
