import { Controller } from "stimulus";
import videojs from 'video.js';

export default class extends Controller {
  connect() {
    if (this.data.has("video")) {
      this.loadPlayer()
    }

    this.element.querySelectorAll(".modal-background, .modal-close").forEach((element) => {
      element.addEventListener("click", this.close.bind(this))
    })

    document.querySelector("html").classList.add('is-clipped')
  }

  disconnect() {
    document.querySelector("html").classList.remove('is-clipped')
    if (this.player)  { this.player.dispose()  }
  }

  loadPlayer() {
    this.player = videojs("#videojs", {
      controlBar: {
        pictureInPictureToggle: !!document.pictureInPictureEnabled
      }
    });
  }

  close() {
    this.element.remove()
  }

  update() {
    let tmpImg = new Image()

    tmpImg.onload = () => {
      let video = this.element.querySelector("video")
      if (video) {
        video.poster = tmpImg.src
      }
    }

    tmpImg.src = event.target.currentSrc || event.target.src;
  }
}
