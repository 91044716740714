import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button", "toggleAll", "presentation" ]

  connect() {
    this.update()
  }

  toggleAll() {
    this.presentationTargets.forEach(p => p.checked = !!this.toggleAllTarget.checked)
    this.toggleAllTarget._tippy.setContent(`Click to ${this.toggleAllTarget.checked ? "uncheck" : "check"} all presentations`)
    this.update()
  }

  update() {
    let checked = this.presentationTargets.filter(d => d.checked)

    switch (checked.length) {
      case 0:
        this.updateConfirm(this.buttonTarget, `Do you really want to unpublish all presentations and notify all users assigned to this project?`)  
        break;
      case this.presentationTargets.length:
        this.updateConfirm(this.buttonTarget, `Do you really want to publish all presentations and notify all users assigned to this project?`)
        break
      default:
        this.updateConfirm(this.buttonTarget, `Do you really want to publish ${checked.length} selected presentation(s) and notify all users assigned to this project? (unchecked presentations will be marked as unpublished)`)
        break;
    }
  }

  updateConfirm(element, content) {
    element.dataset.confirm = content
  }
}
