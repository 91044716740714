import { Controller } from "stimulus"

var passiveSupported = false;

try {
  var options = Object.defineProperty({}, "passive", {
    get: function() {
      passiveSupported = true;
    }
  });

  window.addEventListener("test", options, options);
  window.removeEventListener("test", options, options);
} catch(err) {
  passiveSupported = false;
}

export default class extends Controller {
  connect() {
    this.backgrounds = Array.from(document.querySelectorAll("#background.is-hidden-mobile div.background"))
    this.index       = 0
    this.modal       = document.querySelector("#presentations")
    this.buttons     = this.element.querySelectorAll('.hamburger')
  }

  open() {
    this.modal.classList.add("is-active")
    this.buttons.forEach(b => b.classList.add("is-active"))

    document.documentElement.classList.add("has-modal")

    setTimeout(() => {
      this.modal.style.opacity = 1;
    }, 50)
  }

  opened() {
    return this.modal.classList.contains("is-active")
  }

  close() {
    this.buttons.forEach(b => b.classList.remove("is-active"))
    document.documentElement.classList.remove("has-modal")

    this.modal.style.opacity = 0;

    setTimeout(() => {
      this.modal.classList.remove("is-active")
    }, 200)
  }

  toggle() {
    this.opened() ? this.close() : this.open()
  }

  update(event) {
    let newIndex = this.index === 0 ? 1 : 0

    let tmpImg = new Image()

    tmpImg.onload = () => {
      this.backgrounds[newIndex].style.backgroundImage = `url(${tmpImg.src})`
      this.backgrounds[newIndex].style.opacity = 1
      this.backgrounds[this.index].style.opacity = 0
      this.index = newIndex

      let video = document.querySelector("#preview video")
      if (video) {
        video.poster = tmpImg.src
      }
    }

    tmpImg.src = event.target.currentSrc || event.target.src;

    document.querySelector("#preview .next a").style.opacity = 1
    document.querySelector("#preview .previous a").style.opacity = 1
    document.querySelector("#preview .visual").classList.remove("is-loading")
    document.querySelector("#preview .visual img").style.opacity = 1

    // Check if the preview should be active
    // 
    if (this.element.querySelector("#preview").dataset.previewActive !== undefined) {
      document.addEventListener('touchmove', this.__disableScroll, passiveSupported ? { passive: false } : false);      
    } else {
      document.removeEventListener('touchmove', this.__disableScroll, passiveSupported ? { passive: false } : false);
    }
  }

  __disableScroll(e) {
    e.preventDefault();
  }
}
