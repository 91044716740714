import SlimSelect from 'slim-select';
import Bulma from '@vizuaalog/bulmajs';

import Uploader from './uploader';
import Poller from './poller';

import NProgress from 'nprogress/nprogress.js'
import Rails from '@rails/ujs';

import StarRating from 'star-rating.js'

import tippy from 'tippy.js';

window.Bulma = Bulma

Bulma.start = Bulma.traverseDOM;
Bulma.traverseDOM = function() {};

Turbolinks.scrollTop = null;

let selects = [];
let stars;

document.addEventListener("turbolinks:render", function() {
  if (Turbolinks.scrollTop) {
    document.documentElement.scrollTop = Turbolinks.scrollTop;
    Turbolinks.scrollTop = null;
  }
})

document.addEventListener('turbolinks:load', function() {
  Bulma.start()
  new Uploader()

  stars = new StarRating('.star-rating', {showText: false});
  tippy('[data-tippy-content]', {interactive: true});

  Poller.start();

  document.querySelectorAll("[data-persist-scroll='true']").forEach((element) => {
    element.addEventListener("click", function() {
      Turbolinks.scrollTop = document.documentElement.scrollTop;
    })
  })

  document.addEventListener("ajax:before", NProgress.start)
  document.addEventListener("ajax:complete", function() {
    NProgress.done()
    NProgress.remove()
  })

  const orderSelects = Array.from(document.querySelectorAll('select.order'));
  orderSelects.forEach((element) => {
    element.addEventListener('change', (event) => {
      NProgress.start();
      Turbolinks.scrollTop = document.documentElement.scrollTop;
      Turbolinks.visit(event.target.value);
    })
  })

  const elements = Array.from(document.querySelectorAll('.slimselect'));
  selects = elements.map((element) => {
    let select = new SlimSelect({
      select: element,
      allowDeselect: !!element.dataset.deselect,
      deselectLabel: '×',
      placeholder: element.dataset.placeholder,
      onChange: (selected) => {
        if (element.dataset.submit) {
          NProgress.start();
          Turbolinks.scrollTop = document.documentElement.scrollTop;
          Turbolinks.visit(selected.value || element.dataset.placeholderUrl)
        }
      }
    })

    select.slim.container.style.visibility = "";
    return select
  });
})

document.addEventListener('turbolinks:before-render', function() {
  document.querySelectorAll("[disabled='']").forEach(b => Rails.enableElement(b))

  selects.forEach(s => s.destroy())

  if (stars !== undefined) { stars.destroy() }
})

document.addEventListener('turbolinks:before-cache', function() {
  NProgress.done();
  NProgress.remove();

  Poller.stop();
})

