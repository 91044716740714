import { Controller } from "stimulus"
import Rails from '@rails/ujs';
import Sortable from "sortablejs"
import NProgress from 'nprogress/nprogress.js'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.draggable').forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault()
        event.stopPropagation()
        return false
      })
    })

    this.sortable = Sortable.create(this.element, {
      handle: ".draggable",
      scroll: this.element,
      scrollSensitivity: 100,
      // WTF: Safari?!
      // 
      // Safari has broken drag&drop events from version 13
      // 
      forceFallback: (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1),
      onEnd: this.end.bind(this)
    })
  }

  success() {
    NProgress.done();
    NProgress.remove();

    if (this.element.dataset.successNotification) {
      Bulma.create('notification', {
        body: this.element.dataset.successNotification,
        color: 'success',
        destroyOnDismiss: true,
        dismissInterval: 5000,
        parent: document.getElementById('notifications'),
        isDismissable: true
      }).show();
    }
  }

  error() {
    NProgress.done();
    NProgress.remove();

    if (this.element.dataset.errorNotification) {
      Bulma.create('notification', {
        body: this.element.dataset.errorNotification,
        color: 'danger',
        destroyOnDismiss: true,
        dismissInterval: 5000,
        parent: document.getElementById('notifications'),
        isDismissable: true
      }).show();
    }
  }

  end(event) {
    NProgress.start();

    if (this.element.dataset.persistScroll) {
      Turbolinks.scrollTop = document.documentElement.scrollTop;      
    }

    let url = event.item.querySelector("[data-drag-url]").dataset.dragUrl
    let data = new FormData()
    data.append("position", event.newIndex + 1)
    
    let request = Rails.ajax({
      url: url,
      type: 'PATCH',
      data: data,
      success: this.success.bind(this),
      error: this.error.bind(this)
    })
  }

}
