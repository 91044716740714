import { Controller } from "stimulus"
import Rails from '@rails/ujs';
import NProgress from 'nprogress/nprogress.js'

export default class extends Controller {
  static targets = [ "form" ]

  success(event) {
    return function() {
      NProgress.done();
      NProgress.remove();
      Turbolinks.visit(event.target.href)
    }
  }

  error() {
    NProgress.done();
    NProgress.remove();

    Bulma.create('notification', {
      body: "Can't save presentation draft, please try it again later..",
      color: 'danger',
      destroyOnDismiss: true,
      dismissInterval: 5000,
      parent: document.getElementById('notifications'),
      isDismissable: true
    }).show();
  }

  submit(event) {
    event.preventDefault()
    event.stopPropagation()
    NProgress.start();

    this.formTarget.addEventListener("ajax:success", this.success(event))
    this.formTarget.addEventListener("ajax:error", this.error)

    Rails.fire(this.formTarget, 'submit')
  }
}
