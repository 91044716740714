import { Controller } from "stimulus"
import NProgress from 'nprogress/nprogress.js'
import Bulma from '@vizuaalog/bulmajs';

export default class extends Controller {
  deleteSuccess() {
    this.element.remove()
    Bulma.create('notification', {
      body: `Medium ${this.data.get('name')} deleted...`,
      color: 'success',
      destroyOnDismiss: true,
      dismissInterval: 5000,
      parent: document.getElementById('notifications'),
      isDismissable: true
    }).show();

    this.hideProgressBar()
  }

  deleteFailure() {
    Bulma.create('notification', {
      body: `Can't delete ${this.data.get('name')}...`,
      color: 'danger',
      destroyOnDismiss: true,
      dismissInterval: 5000,
      parent: document.getElementById('notifications'),
      isDismissable: true
    }).show();

    this.hideProgressBar()
  }

  hideProgressBar() {
    NProgress.done()
    NProgress.remove()
  }
}
