import Uppy from 'uppy/dist/uppy'
import 'unorm/lib/unorm'

class Uploader {
  constructor(selector = ".uppy-dashboard") {
    const element = document.querySelector(selector);
    if (element === null || !!element.attributes.disabled) return;
    if (element.Uppy) return element.Uppy;

    this.callback = element.dataset.callback;
    this.token    = document.querySelector("meta[name=\"csrf-token\"]").content;

    const maxNumberOfFiles = element.dataset.maxFiles || null;

    const uppy = Uppy.Core({
      debug: false,
      autoProceed: false,
      allowMultipleUploads: false,
      restrictions: {
        maxNumberOfFiles: maxNumberOfFiles,
        allowedFileTypes: [
          '.gif',
          '.jpg',
          '.jpeg',
          '.gif',
          '.png',
          '.mpg',
          '.mpeg',
          '.mp4',
          '.avi',
          '.mov',
          '.flv',
          '.m4v',
          '.webm',
          'image/gif',
          'image/jpg',
          'image/jpeg',
          'image/gif',
          'image/png',
          'application/x-mp4',
          'application/x-mpg',
          'video/mpeg',
          'video/quicktime',
          'video/x-la-asf',
          'video/x-ms-asf',
          'video/x-msvideo',
          'video/x-sgi-movie',
          'video/x-flv',
          'flv-application/octet-stream',
          'application/octet-stream',
          'video/3gpp',
          'video/3gpp2',
          'video/3gpp-tt',
          'video/BMPEG',
          'video/BT656',
          'video/CelB',
          'video/DV',
          'application/x-mov',
          'video/H261',
          'video/H263',
          'video/H263-1998',
          'video/H263-2000',
          'video/H264',
          'video/JPEG',
          'video/MJ2',
          'video/MP1S',
          'video/MP2P',
          'video/MP2T',
          'video/mp4',
          'video/MP4V-ES',
          'video/MPV',
          'video/mpeg4',
          'video/mpeg4-generic',
          'video/nv',
          'video/parityfec',
          'video/pointer',
          'video/raw',
          'application/x-avi',
          'video/rtx',
          'video/x-ms-wmv',
          'video/x-m4v',
          'video/ogg',
          'video/webm'
        ]
      },
      meta: {
        callback: this.callback,
        token: this.token
      },
      onBeforeFileAdded: this.onBeforeFileAdded
    })
    .use(Uppy.Dashboard, {
      target: '.uppy-dashboard',
      trigger: '.uppy-trigger',
      inline: false,
      locale: {
        strings: {
          dropPaste: 'Drag & drop files here or %{browse}',
          filesUploadedOfTotal: {
            0: '%{smart_count} file remaining',
            1: '%{smart_count} files remaining'
          }
        }
      },
      closeModalOnClickOutside: true,
      showLinkToFileUploadResult: false,
      replaceTargetContent: true,
      showProgressDetails: true,
      proudlyDisplayPoweredByUppy: false,
      width: '100%',
      metaFields: [
        { id: 'name', name: 'Name', placeholder: 'file name' },
        { id: 'memo', name: 'Memo' },
        { id: 'description', name: 'Description' }
      ]
    })
    .use(Uppy.Tus, {
      endpoint: '/files/',
      chunkSize: 10*1024*1024,
      autoRetry: true,
      limit: 1
    })
    .on('upload-success', this.onUploadSuccess.bind(this))
    .on('complete', this.onComplete.bind(this))

    this.__uppy = uppy;
    element.Uppy = this;

    return this;
  }

  onBeforeFileAdded(currentFile, files) {
    const filename = currentFile.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return Object.assign({}, currentFile, {name: filename})
  }

  onUploadSuccess(file, data) {
    // NOTE: not sure if should be normalized with normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    //

    let uploadedFileData = JSON.stringify({
      name: file.meta.name,
      memo: file.meta.memo,
      description: file.meta.description,
      file: {
        id: data.uploadURL,
        storage: "cache",
        metadata: {
          filename:  file.name,
          size:      file.size,
          mime_type: file.type,
        }
      }
    })

    let callback = fetch(file.meta.callback, {
      headers: {
        "X-CSRF-TOKEN": file.meta.token,
        "Content-Type": "application/json"
      },
      method: 'POST',
      credentials: "same-origin",
      body: uploadedFileData
    })

    this.__uppy.setFileState(file.id, { callback: callback })
  }

  onComplete(result) {
    const uppy = this.__uppy;

    if (result.failed.length === 0) {
      let callbacks = result.successful.map(d => d.callback)

      Promise.all(callbacks).then((responses) => {
        responses.forEach((r, i) => {
          if (r.ok === false) {
            const message = `Processing of ${result.successful[i].name} failed.`
            uppy.setFileState(result.successful[i].id, { error: "Processing failed." })
            uppy.setState({
              error: "Something went wrong during processing files after upload. Please try it again by clicking on Retry button."
            })
            uppy.info({ message: message}, 'error', 5000)
            uppy.log(`${message}, ${r.status}: ${r.statusText}`, 'error')
          }
        })
        if (uppy.getState().error === null) {
          location.reload();
        }
      });

    } else {
      uppy.setState({ error: `Upload of ${failed} file(s) failed. Please try it again by clicking on Retry button.` })
    }
  }
}

export default Uploader
